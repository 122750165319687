<template>
  <div class="order">
    <v-col cols="12" md="8" offset-md="2">
      <v-row>
        <CarSelector/>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import CarSelector from '../components/CarSelector/CarSelector.vue';

export default {
  components: { CarSelector },
  name: 'car-selector',
  created() {
    this.$hero.$emit('setTitle', '');
    this.$hero.$emit('setBreadcrumbs', [
      {
        text: 'home',
        exact: true,
        to: { name: 'home' },
      },
      {
        text: 'assortiment',
        to: { name: 'order' },
      },
    ]);
  },
};
</script>

<style scoped lang="scss">

</style>
