<template>
  <v-expansion-panels v-model="panel" multiple readonly>
    <v-expansion-panel>
      <v-expansion-panel-header hide-actions
        >1. Kies uw merk</v-expansion-panel-header
      >
      <v-expansion-panel-content eager>
        <v-row>
          <v-col cols="3" md="2" v-for="brand in brands" :key="brand.id">
            <SelectableOption
              :item="brand"
              :display-name="false"
              v-model="selected.brand"
              @selected="setBrand(brand)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel id="models">
      <v-expansion-panel-header hide-actions
        >2. Kies uw model</v-expansion-panel-header
      >
      <v-expansion-panel-content eager>
        <v-row>
          <v-col cols="3" md="2" v-for="model in models" :key="model.id">
            <SelectableOption
              :item="model"
              v-model="selected.model"
              @selected="setModel(model)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel id="implementations">
      <v-expansion-panel-header hide-actions
        >3. Kies uw uitvoering</v-expansion-panel-header
      >
      <v-expansion-panel-content eager>
        <v-row>
          <v-col
            cols="3"
            md="2"
            v-for="implementation in implementations"
            :key="implementation.id"
          >
            <SelectableOption
              :item="implementation"
              v-model="selected.implementation"
              @selected="setImplementation(implementation)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import SelectableOption from "../SelectableOption.vue";
import { fetchAll as fetchAllBrands } from "@/api/order/brand.js";
import { fetch as fetchModels } from "@/api/order/model.js";
import { fetch as fetchImplementation } from "@/api/order/implementation.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CarSelector",
  components: { SelectableOption },
  data() {
    return {
      panel: [0],
      brands: [],
      models: [],
      implementations: [],
      selected: {
        brand: null,
        model: null,
        implementation: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedBrand: "filter/selectedBrand",
      selectedModel: "filter/selectedModel",
    }),
  },
  async created() {
    await this.getBrands();

    if (this.selectedBrand) {
      this.setBrand(this.selectedBrand);
    }
  },
  methods: {
    ...mapMutations({
      setFilters: "filter/setFilters",
    }),
    setBrand(brand) {
      this.selected.brand = brand;
      this.selected.model = null;
      this.selected.implementation = null;
      this.panel = [0, 1];
      setTimeout(() => {
        this.$vuetify.goTo("#models", { duration: 400 });
      }, 100);

      this.getModels(this.selected.brand.id);
    },
    setModel(model) {
      this.selected.model = model;
      this.selected.implementation = null;
      this.panel = [0, 1, 2];
      setTimeout(() => {
        this.$vuetify.goTo("#implementations", { duration: 400 });
      }, 100);

      this.getImplementation(this.selected.model.id);
    },
    setImplementation(implementation) {
      this.selected.implementation = implementation;
      this.panel = [0, 1, 2];

      this.setFilters({
        brand: this.selected.brand,
        model: this.selected.model,
        implementation: this.selected.implementation,
      });

      this.$router.push({ name: "products" });
    },
    async getBrands() {
      const brandRequest = await fetchAllBrands(1, 100);
      this.brands = brandRequest.data ? brandRequest.data : [];
    },
    async getModels(brandId) {
      const modelRequest = await fetchModels(brandId);
      this.models = modelRequest.data ? modelRequest.data.data : [];

      if (this.selectedModel) this.setModel(this.selectedModel);
    },
    async getImplementation(modelId) {
      const implementationRequest = await fetchImplementation(modelId);
      this.implementations = implementationRequest.data
        ? implementationRequest.data.data
        : [];
    },
  },
};
</script>

<style scoped></style>
